/* change this to be on mobile, currently like this for testing */
import { useEffect, useState } from 'react';
import { useIsMobile } from '../use-is-mobile';

const getFilteredComponents = modules => {
  const isMobile = useIsMobile();
  const [components, setComponents] = useState(modules);
  useEffect(() => {
    if (isMobile) {
      setComponents(modules.filter(module => module.displayOnMobile !== false));
    }
  }, [isMobile]);
  return components;
};

export default getFilteredComponents;

import {
  ASTNode,
  DocumentNode,
  FragmentDefinitionNode,
  InlineFragmentNode,
  OperationDefinitionNode,
} from 'graphql/language/ast';
import { print } from 'graphql/language/printer';
import _ from 'lodash';

/*
Note: This query splitter operates under the assumption the the modules queries for home, landing, detail and dynamic
pages continue to exist in their current shape
  
 query LandingPage(
      $preview: Boolean = false
      $tagSlug: String!
      $pageSlug: String!
    ) {
      ${collection}(
        preview: $preview
        where: ${filter}
        limit: 1
      ) {
        items {
          modulesCollection {
            items {}
          }
        }
    }

which result in an AST that resembles
{
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            selectionSet: {
                selections: [
                    {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "pageLandingCollection"
                        },
                        selectionSet: {
                            selections: [
                                {
                                    "kind": "Field",
                                    "name": {
                                      "kind": "Name",
                                      "value": "items"
                                    },
                                    selectionSet: {
                                        selections: [
                                            {
                                                "kind": "Field",
                                                "name": {
                                                  "kind": "Name",
                                                  "value": "modulesCollection"
                                                },
                                                selectionSet: {
                                                    selections: [
                                                        {
                                                            "kind": "Field",
                                                            "name": {
                                                              "kind": "Name",
                                                              "value": "items"
                                                            },
                                                            selectionSet: {
                                                                selections: [
                                                                    ...LIST_OF_INLINE_FRAGMENTS <<--- List of InlineFragmentNodes 
                                                                ]
                                                            }
                                                            
                                                        }
                                                    ]
                                                }
                                            }
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
           
        },
        --- List of FragmentDefinitions which are the query definitions for the inline framgnets
        {
          "kind": "FragmentDefinition",
          "name": {
            "kind": "Name",
            "value": "Audio"
          },
          ....
        },
        {
          "kind": "FragmentDefinition",
          "name": {
            "kind": "Name",
            "value": "SpotifyPlayer"
          },
          ...
        }   
    ]
}

Specification for GraphQL : https://spec.graphql.org/
*/

type FragmentDefinitionMap = Record<string, FragmentDefinitionNode>;

const MAX_QUERY_BYTE_SIZE = 5200;

/**
 * Function that keeps chunking the initial query into small queries until they are under the size limit
 * @param {DocumentNode} initialQuery A graphql query root
 * @returns {Array<DocumentNode>} Array of either the initial query of smaller chunks of the initial query
 */

const prepareQuery = (initialQuery: DocumentNode): Array<DocumentNode> => {
  if (isQueryWithinSizeLimit(initialQuery)) {
    return [initialQuery];
  }
  const fragmentDefinitionsMap = buildFragDefs(initialQuery);

  // https://spec.graphql.org/June2018/#OperationDefinition essentially the node that contains the executable query. It is one possible type of an
  // Executable Definition (https://spec.graphql.org/June2018/#ExecutableDefinition)
  const operationDefinitionNode: OperationDefinitionNode = initialQuery.definitions.find(
    node => node.kind === 'OperationDefinition',
  ) as OperationDefinitionNode;

  let chunks = 2;
  let newQueries: Array<DocumentNode> = [initialQuery];

  // check if one of the queries is within the limit (if one is, they all are)
  // and while it is not, call splitQuery again with the original query to be split again by one more factor.
  while (!isQueryWithinSizeLimit(newQueries[0])) {
    newQueries = splitQuery(
      operationDefinitionNode!,
      chunks,
      fragmentDefinitionsMap,
    ).map(buildQuery);
    chunks += 1;
  }
  return newQueries;
};

/**
 * Function that checks if a graphql query size is under or equal to the limit
 * @param {DocumentNode} query A graphql query root
 * @returns {boolean}
 */

const isQueryWithinSizeLimit = (query: DocumentNode): boolean => {
  return new TextEncoder().encode(print(query)).length <= MAX_QUERY_BYTE_SIZE;
};

/**
 * Function that rebuilds a graphql DocumentNode
 * @param {[
    OperationDefinitionNode,
    Set<FragmentDefinitionNode>,
  ]} queryDefinitionAndModules A tuple of graphql OperationDefinitionNode and a set of its needed FragmentDefinitionNodes used to rebuild the DocumentNode.
 * @returns {DocumentNode} The root of a graphql query
 */
const buildQuery = (
  queryDefinitionAndModules: [
    OperationDefinitionNode,
    Set<FragmentDefinitionNode>,
  ],
): DocumentNode => {
  const [queryDefinition, modules] = queryDefinitionAndModules;
  return {
    kind: 'Document',
    definitions: [queryDefinition, Array.from(modules)].flat(),
  };
};

/**
 * Function that extracts the fragment definitions from the original query and builds a map from which we can look them up by name.
 * As we split the initial query into smaller queries, we will end up including a subset of the fragments included in the initial query
 * in the chunked queries, which will require us to find the associated FragmentDefinition to include in the chunked query, so we create a map to look
 * up by fragment name
 * @param {DocumentNode} query A graphql DocumentNode (the root of the graphl AST).
 * @returns {FragmentDefinitionMap} A map of FragmentDefinitionNode keyed by their name
 */
const buildFragDefs = (query: DocumentNode): FragmentDefinitionMap => {
  const fragmentDefs: FragmentDefinitionMap = {};
  query.definitions
    .filter(d => d.kind === 'FragmentDefinition')
    .forEach(d => {
      // FragmentDefinition is the other type of Executable Definition (https://spec.graphql.org/June2018/#ExecutableDefinition)
      // https://spec.graphql.org/June2018/#FragmentDefinition they are reusable executable query fragments
      fragmentDefs[
        (d as FragmentDefinitionNode).name.value
      ] = d as FragmentDefinitionNode;
    });
  return fragmentDefs;
};

/**
 * Function that splits an OperationDefinitionNode into x number of chunks and returns the chunks with the needed FragmentDefinitionNodes for the new query chunk
 * @param {OperationDefinitionNode} queryOperationDefinition A graphql OperationDefinitionNode (https://spec.graphql.org/June2018/#OperationDefinition).
 * @param {number} chunkBy The number of chunks to split the query into.
 * @param {FragmentDefinitionMap} fragmentDefs A map of all of the fragment definitions (https://spec.graphql.org/June2018/#FragmentDefinition) in the original query
 * @returns {Array<[OperationDefinitionNode, Set<FragmentDefinitionNode>]>} A chunk of the queryOperationDefinition and its needed FragmentDefinitionNodes
 */
const splitQuery = (
  queryOperationDefinition: OperationDefinitionNode,
  chunkBy: number,
  fragmentDefs: FragmentDefinitionMap,
): Array<[OperationDefinitionNode, Set<FragmentDefinitionNode>]> => {
  // find the list of module fragments that have been included in the original query
  const modules = findQueryModules(queryOperationDefinition);
  // divide that list of modules by the chunkBy factor to be split up amongst multiple queries
  const chunk = Math.ceil(modules.length / chunkBy);
  const chunksArray = [];

  for (let i = 0; i < chunkBy; i += 1) {
    const startIndex = i * chunk;
    const endIndex = startIndex + chunk;
    const modulesChunk = modules.slice(startIndex, endIndex);
    const modulesChunkFrags = unionSets(
      modulesChunk.map((m: InlineFragmentNode) =>
        // for each inline fragment, get the set of fragment definitions needed for the fragment query from our FragmentDefinition map
        getRequiredFrags(m, fragmentDefs),
      ),
    );

    // we now have an array of chunkBy number of objects that include the list of modules fragments in the chunk and all of its
    // needed FragmentDefinitions including definitions of all possible fragment children.
    chunksArray.push({ modulesChunk, modulesChunkFrags });
  }

  return chunksArray.map(c => [
    // using the original OperationDefinitionNode, copy it and reset the modules fragments to the determined subset
    setModules(queryOperationDefinition, c.modulesChunk),
    c.modulesChunkFrags,
  ]);
};

/**
 * Function that dives deep into the OperationDefinitionNode to find the items on the modulesCollection property which lists all module fragments included in the original query
 * @param {OperationDefinitionNode} queryOperationDefinition A graphql OperationDefinitionNode from the original query (https://spec.graphql.org/June2018/#OperationDefinition).
 * @returns {Array<InlineFragmentNode>} The value of the original query's modulesCollection property (https://spec.graphql.org/June2018/#InlineFragment)
 */
const findQueryModules = (
  queryOperationDefinition: OperationDefinitionNode,
): Array<InlineFragmentNode> => {
  // @ts-ignore
  return queryOperationDefinition.selectionSet.selections[0].selectionSet
    .selections[0].selectionSet.selections[0].selectionSet.selections[0]
    .selectionSet.selections;
};

const unionSets = (
  arrayOfSets: Array<Set<FragmentDefinitionNode>>,
): Set<FragmentDefinitionNode> => {
  return new Set(
    arrayOfSets.reduce(
      (a, c) => a.concat(Array.from(c)),
      [] as Array<FragmentDefinitionNode>,
    ),
  );
};

/**
 * Function that recursively resolves all fragment definitions needed for fragmentspreadnodes in the AST
 * @param {ASTNode} node A graphql ASTNode to traverse.
 * @param {FragmentDefinitionMap} fragmentDefs A map of all of the fragment definitions in the original query
 * @returns {Set<FragmentDefinitionNode>} A set of the fragment definitions needed for the ASTnode
 */
const getRequiredFrags = (
  node: ASTNode,
  fragmentDefs: FragmentDefinitionMap,
): Set<FragmentDefinitionNode> => {
  // node is initially an InlineFragment.
  // This recursive function keeps performing a depth first search until we find a FragmentSpread (https://spec.graphql.org/June2018/#FragmentSpread)

  // if we find a FragmentSpread:
  if (node.kind === 'FragmentSpread') {
    const s: Set<FragmentDefinitionNode> = new Set();
    // look up the FragmentDefinition for this FragmentSpread by name
    const fragment: FragmentDefinitionNode = fragmentDefs[node.name.value];
    // we use a Set because it's possible that a fragment spread may be included multiple times if it's included in multiple other queries, but
    // we only need to fragment definition once.
    s.add(fragment);
    // do the same search for this fragment and merge into our master set of FragmentDefinitions
    return unionSets([s, getRequiredFrags(fragment, fragmentDefs)]);
  }

  // If it's not a FragmentSpread and it doesn't have a selection set then we've reached a terminal node, return an empty set
  if (!('selectionSet' in node) || node.selectionSet === undefined) {
    return new Set();
  }

  // otherwise call this function for all of the nodes in this node's selections array
  const arrayOfSets = node.selectionSet!.selections.map(n =>
    getRequiredFrags(n, fragmentDefs),
  );

  return unionSets(arrayOfSets);
};

/**
 * Function to set the queryOperationDefinition's items property on the modulesCollection property to a subset of the original query's modules.
 * @param {OperationDefinitionNode} queryOperationDefinition A graphql OperationDefinitionNode to mutate.
 * @param { Array<InlineFragmentNode>} modules An array of graphql InlineFragmentNodes to set on the queryOperationDefinition's modulesCollection property.
 * @returns {OperationDefinitionNode} The mutated OperationDefinitionNode set with the new modulesCollection property
 */

const setModules = (
  queryOperationDefinition: OperationDefinitionNode,
  modules: Array<InlineFragmentNode>,
): OperationDefinitionNode => {
  const newQueryOperationDefinition = _.cloneDeep(queryOperationDefinition);
  // @ts-ignore
  newQueryOperationDefinition.selectionSet.selections[0].selectionSet.selections[0].selectionSet.selections[0].selectionSet.selections[0].selectionSet.selections = modules;
  return newQueryOperationDefinition;
};

export default prepareQuery;

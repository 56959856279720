import { gql } from '@apollo/client';

import { USE_CONTENT_PREVIEW_FILTER } from 'constants/query';
import { getLocalizedField } from 'utils/get-localized-field';
import { DYNAMIC_FRAGMENT_LITERALS } from 'queries/fragment-literals';
import {
  COMPONENT_FRAGMENTS,
  MODULE_FRAGMENTS,
  DYNAMIC_FRAGMENTS,
} from 'queries/fragments';
import { FRAGMENTS } from './navigation';

const getDetailFilter = USE_CONTENT_PREVIEW_FILTER
  ? `{
          OR: [
            { slug: $pageSlug, navigationTag: { slug: $tagSlug } }
            { slug: $pageSlug, navigationTag: { sys: { id: $tagSlug } } }
          ]
        }`
  : `{ slug: $pageSlug, navigationTag: { slug: $tagSlug } }`;

const PAGE_DETAIL_GLOBAL_QUERY = {
  body: ({
    collection = 'pageDetailGlobalCollection',
    collectionLocale = '',
    filter = getDetailFilter,
  }) => gql`
    query GlobalDetailPage(
      $preview: Boolean = false
      $tagSlug: String!
      $pageSlug: String!
      $locale: String!
    ) {
      ${collection}(
        preview: $preview
        where: ${filter}
        locale: $locale
        limit: 1
      ) {
        items {
          navigationTag {
            title
          }
          categoryTag {
            title
          }
          slug
          eyebrowOverride
          meta {
            ...MetaFields
          }
          pageIndexation
          hero {
            ...HeroFields
          }
          introText
          author {
            ...AuthorFields
          }
          publishDate
          pageBackgroundColor
          pageDefaultTextColor
          contentfulMetadata {
            tags {
                id
                name
            }
          }
          lowerNavigation {
              slug
              navigationItemsCollection {
              items {
                type: __typename
                ... on NavigationTag {
                  ...NavigationTag
                }
                ... on Dropdown {
                  ...Dropdown
                }
              }
            }
            isBackButton
            isBreadcrumb
            showLocaleSelector
          }
          relatedContent {
            ${DYNAMIC_FRAGMENT_LITERALS.CURATED_LIST(
              getLocalizedField(collectionLocale),
            )}
          }
        }
      }
    }
    ${COMPONENT_FRAGMENTS.CTA}
    ${DYNAMIC_FRAGMENTS.CURATED_LIST_PAGE_DETAIL(
      getLocalizedField(collectionLocale),
    )}
    ${DYNAMIC_FRAGMENTS.CURATED_LIST_PAGE_GLOBAL}
    ${DYNAMIC_FRAGMENTS.CURATED_LIST_PAGE_LANDING(
      getLocalizedField(collectionLocale),
    )}
    ${DYNAMIC_FRAGMENTS.CURATED_LIST_PAGE_TRENDS}
    ${COMPONENT_FRAGMENTS.META}
    ${COMPONENT_FRAGMENTS.HERO('SplitViewDetail')}
    ${COMPONENT_FRAGMENTS.AUTHOR}
    ${COMPONENT_FRAGMENTS.IMAGE}
    ${FRAGMENTS.NAVIGATION_TAG}
    ${FRAGMENTS.LINK}
    ${FRAGMENTS.DROPDOWN}
    ${COMPONENT_FRAGMENTS.LIST_ITEM}
    ${MODULE_FRAGMENTS.LIST}
    ${COMPONENT_FRAGMENTS.CONTENT_TILE_NO_TRENDS}
    ${MODULE_FRAGMENTS.TILE_CAROUSEL_ONLY_CONTENT_TILE}
    ${MODULE_FRAGMENTS.SPLIT_VIEW_DETAIL}
    ${MODULE_FRAGMENTS.FEATURE_CARD}
  `,
  modules: ({
    collection = 'pageDetailGlobalCollection',
    filter = getDetailFilter,
  }) => gql`
    query GlobalDetailPage(
      $preview: Boolean = false
      $tagSlug: String!
      $pageSlug: String!
      $locale: String!
    ) {
      ${collection}(
        preview: $preview
        where: ${filter}
        locale: $locale
        limit: 1
      ) {
        items {
          modulesCollection {
            items {
              ... on ModuleImage {
                ...Image
              }
              ... on ModuleAudio {
                ...Audio
              }
              ... on ModuleQuote {
                ...Quote
              }
              ... on ModuleStats {
                ...Stats
              }
              ... on ModuleSpotifyPlayer {
                ...SpotifyPlayer
              }
              ... on ModuleMarkdownText {
                ...MarkdownText
              }
              ... on ModuleFeatureCardContainer {
                ...FeatureCardContainer
              }
              ... on ModuleFeatureCard {
                ...FeatureCard
              }
              ... on ModuleTopicCarousel {
                ...TopicCarousel
              }
              ... on ModuleTopic {
                ...Topic
              }
              ... on ModuleInlineForm {
                ...InlineForm
              }
              ... on ModuleInlineCard {
                ...InlineCard
              }
              ... on ModuleAccordion {
                ...Accordion
              }
              ... on ModuleVideoFeatureCard {
                ...VideoFeatureCard
              }
              ... on ModuleSplitView {
                ...SplitView
              }
              ... on ModuleTileCarousel {
                ...TileCarousel
              }
              ... on ModuleGallery {
                ...Gallery
              }
              ... on ModuleSectionDivider {
                ...SectionDivider
              }
              ... on CtaBanner {
                ...ctaBanner
              }
              ... on ModuleFootnotes {
                ...Footnotes
              }
            }
          }
        }
      }
    }
    ${COMPONENT_FRAGMENTS.CTA}
    ${COMPONENT_FRAGMENTS.IMAGE}
    ${COMPONENT_FRAGMENTS.STAT_CARD}
    ${COMPONENT_FRAGMENTS.CTA_CARD}
    ${COMPONENT_FRAGMENTS.CTA_BANNER}
    ${MODULE_FRAGMENTS.IMAGE}
    ${MODULE_FRAGMENTS.AUDIO}
    ${MODULE_FRAGMENTS.VIDEO}
    ${MODULE_FRAGMENTS.QUOTE}
    ${MODULE_FRAGMENTS.STATS}
    ${MODULE_FRAGMENTS.SPOTIFY_PLAYER}
    ${MODULE_FRAGMENTS.MARKDOWN_TEXT}
    ${MODULE_FRAGMENTS.FEATURE_CARD_CONTAINER}
    ${MODULE_FRAGMENTS.FEATURE_CARD}
    ${MODULE_FRAGMENTS.TOPIC_CAROUSEL}
    ${MODULE_FRAGMENTS.TOPIC}
    ${MODULE_FRAGMENTS.INLINE_FORM}
    ${MODULE_FRAGMENTS.INLINE_CARD}
    ${MODULE_FRAGMENTS.ACCORDION}
    ${COMPONENT_FRAGMENTS.LIST_ITEM}
    ${MODULE_FRAGMENTS.LIST}
    ${MODULE_FRAGMENTS.VIDEO_FEATURE_CARD}
    ${MODULE_FRAGMENTS.SPLIT_VIEW}
    ${MODULE_FRAGMENTS.TILE_CAROUSEL}
    ${COMPONENT_FRAGMENTS.CONTENT_TILE_NO_TRENDS}
    ${MODULE_FRAGMENTS.GALLERY}
    ${MODULE_FRAGMENTS.SECTION_DIVIDER}
    ${MODULE_FRAGMENTS.FOOTNOTES}
  `,
};

export default PAGE_DETAIL_GLOBAL_QUERY;
